// alert("ok56")
$(document).ready(function(){
    $(".nav-btn").click(function(){ 
        (this).toggleClass("active");
    });


    // $('body').on('click', '.sidebar-cards__title', function(e) {
    //     $(this).toggleClass('active');
    //     $(this).next('.sidebar-cards__data').stop().slideToggle();
    // });


});
$('body').on('click', '.sidebar-cards__title', function(e) {
    $(this).toggleClass('active');
    $(this).next('.sidebar-cards__data').stop().slideToggle();
});